import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByKot',
    pure: false
})

export class OrderByKotPipe implements PipeTransform {
    transform(items: any[], args: string[]): any {
        return items.filter(item => args.includes(item.KOTBy));
    }
}