import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from 'src/app/service/MessagingService';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messagingService: MessagingService
  ) {}
  title = 'Ipos-kitchen';
  ngOnInit() {}
}
