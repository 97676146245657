import { Component, Input,OnInit } from '@angular/core';
import { KDSService } from '../service/kds-service';

@Component({
	selector: 'app-print-kot-receipt',
	templateUrl: './print-kot-receipt.component.html',
	styleUrls: ['./print-kot-receipt.component.css']
})
export class PrintKotReceiptComponent implements OnInit {

	@Input() showDetails: any;
	@Input() taxes?: [];
  currentDate=new Date();

	constructor(private kdsService: KDSService) { }

	ngOnInit(): void { }

	print(req): void {
    this.kdsService.PrintKOT(req).subscribe((res) => {

    });
	}

}
