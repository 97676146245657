import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KdsViewComponent } from './kds-view/kds-view.component';

const routes: Routes = [
  { path: 'kds-view', component: KdsViewComponent},
  { path: '', pathMatch: 'full', redirectTo: 'kds-view'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
