<audio id="audio-song" src="./../../assets/kds-sound.mp3" preload="auto" style="display: none;"></audio>
<div id="kdsView" class="">
    <div class="tp-heading">
        <div class="logo">
            KDS
            <!-- <p class="mb-0">Kitchen | Direct Order</p> -->
        </div>
        <ul class="order-status">
            <li><span class="total-order-txt"><i class="fa fa-first-order" aria-hidden="true"></i> Total Order:</span>
                {{ totalOrdersCount }}</li>
            <li><span class="pending-order-txt">Pending:</span> {{ pendingOrdersCount }}</li>
            <li><span class="delivery-order-txt">Delivered:</span> {{ deliveredOrdersCount }}</li>
            <li><span class="rejected-order-txt">Rejected:</span> {{ RejectedOrdersCount }}</li>
        </ul>
        <div class="navigation">
            <a class="cur-pointer mr-2" [ngClass]="[(display=='orderView')?'active':'']"
                (click)="(display='orderView')">Order View</a>
            <a class="cur-pointer ml-2" [ngClass]="[(display=='kotView')?'active':'']" (click)="(display='kotView')">Kot
                View</a>
        </div>

    </div>
    <ng-container *ngIf="display=='orderView'; else second">
        <div class="mt-2">
            <div class="d-flex mb-2 flex-wrap filter-row">
                <label class="mb-0 mr-2 fw-b">Filter By: </label>
                <label class="mb-0">Order Wise</label>
                <p-radioButton name="group2" value="orderwise" [(ngModel)]="selectedValue2" class="ml-1 mr-2">
                </p-radioButton>
                <label class="mb-0">Item Wise</label>
                <p-radioButton name="group2" value="itemwise" [(ngModel)]="selectedValue2" class="ml-1 mr-2">
                </p-radioButton>
                <div class="date-filter mr-2">
                    Date:
                  <span class="startdate">
                    <p-calendar placeholder="From Date" [(ngModel)]="startDate" [showIcon]="true" class="mr-2" [maxDate]="(endDate || currentDate)"
                    [readonlyInput]="true" [showButtonBar]=true></p-calendar>
                  </span>
                  <span class="enddate">
                    <p-calendar placeholder="To Date" [(ngModel)]="endDate" [showIcon]="true" [minDate]="startDate" [maxDate]="currentDate"
                    [readonlyInput]="true" [showButtonBar]=true></p-calendar>
                  </span>
                </div>
                <div class="status-filter mr-2">
                    Status:
                    <select class="filter-input" [(ngModel)]="status">
                        <option value="">Select Status</option>
                        <option selected value="0">Pending</option>
                        <option value="1">Delivered</option>
                        <option value="2">Cancelled</option>
                        <option value="3">All</option>
                    </select>
                </div>
                <input type="number" placeholder="Search By Id" [(ngModel)]="searchByOrderId" class="filter-input">
                <button type="button" (click)="searchByFilter()" class="btn btn-success ml-2">SEARCH</button>
                <p (click)="backToCurrentOrders()" class="link ml-2" *ngIf="showCurrentLink">Back To Current List</p>
                <i class="fa fa-bell fa-2x icon-pos" [ngClass]="[showCSS ? 'notification' : '']"></i>
            </div>
            <div class="odr-table-view">
                <ul class="order-table-heading">
                    <li class="neworder" colspan="3">New Order List</li>
                    <li class="status">Status</li>
                    <li class="action">Action</li>
                </ul>

                <div class="table-leftbox">
                <ul *ngFor="let order of orderDetails | orderByKot:['Qr','App']" class="order-table-data">
                    <li>
                        {{ order.UserName }} <span *ngIf="order?.MobileNo">-{{order?.MobileNo}} <br><b> Table/Room : -{{order?.TableName}}</b></span>
                        <br>
                        {{ order.OrderId }} - {{ order.TotalItems }} | {{ order.TotalQty }} Qty
                        <br>
                        {{ order.KOTBy }} | {{ order.OutletName }} | <span class="link"
                            (click)="viewDetails(order);">View</span>
                    </li>

                    <li>
                        <i class="fa fa-clock-o"></i> {{ order.orderedTime }} <span *ngIf="(order.Delivered==0)">hours ago</span><br />
                        <i class="fa fa-money"></i> Rs {{ order.AppFinalAmt }}<br />
                        <i class="fa fa-rocket"></i> {{ order.Address }}
                    </li>
                    <li>
                        <ng-container *ngIf="(order.OrderActionId==5); else second">
                            <span [ngClass]="{'text-danger': order.OrderActionIdstatus === 'Pending',
                             'text-success' : order.OrderActionIdstatus === 'Delivered',
                             'text-warning' : order.OrderActionIdstatus === 'Cancelled' }">
                                {{ order.OrderActionIdstatus }}
                            </span>
                        </ng-container>
                        <ng-template #second>
                            <span [ngClass]="{'text-danger': order.DeliveredStatus === 'Pending',
                            'text-success' : order.DeliveredStatus === 'Delivered',
                            'text-warning' : order.DeliveredStatus === 'Cancelled' }">
                            {{ order.DeliveredStatus }}
                        </span> <br />
                            <span *ngIf="(order.OrderActionId==1)"> Order Time : {{ order.reverseTime}} </span>
                        </ng-template>
                    </li>
                    <li>
                        <ng-container *ngIf="(order.OrderActionId==0); else other">
                            <span class="link" (click)="orderStatus('1', order)">Accept </span>|
                            <span class="link" (click)="orderStatus('2', order)"> Decline</span>
                        </ng-container>
                        <ng-template #other>
                            <ng-container *ngIf="(order.OrderActionId==1)">
                                <span (click)="updateAdminStatus(order, false)" class="cur-pointer link">{{
                                    order.OrderActionIdstatus }}</span>
                            </ng-container>
                            <ng-container *ngIf="(order.OrderActionId==1 || order.OrderActionId==2)">
                                <span class="cur-pointer link">
                                   <span *ngIf="order.OrderActionId==1" (click)="updateAdminStatus(order, true)"> | Ready To Deliver </span>
                                   <span *ngIf="order.OrderActionId==2" (click)="showdelpersons = true; selectedOrder=order">{{ order.OrderActionIdstatus }}</span>
                                 </span>
                            </ng-container>
                            <ng-container *ngIf="(order.OrderActionId==3)">
                                <span>{{ order.OrderActionIdstatus }}</span><br />
                                <span>{{ order.DeliveryBoyName }} - {{ order.DeliveryMobileNo }}</span><br />
                                <span *ngIf="(order.Delivered=='0')"><i class="fa fa-hourglass-half" title="Processing"></i></span>
                                <span *ngIf="(order.Delivered=='1')">
                                    <i class="fa fa-thumbs-up" title="Delivered"></i>
                                    <span> {{ order.deliveredTime }}</span>
                                </span>
                                <span *ngIf="(order.Delivered=='2')">
                                    <i class="fa fa-thumbs-down" title="Cancelled"></i>
                                </span>
                                <span *ngIf="(order.DeliveryboyCancelordercount>0)">
                                    <p class="link" (click)="getRejectedReasons(order.OrderId)">Reject Reason</p>
                                </span>
                            </ng-container>
                        </ng-template>
                    </li>
                </ul>
              </div>
              <div class="table-rightbox">
                <div class="table-rightbox-inner">
                 <ng-container *ngIf="detailView; else empty">
                  <div class="toparea">
                      <h4 class="ord-heading">ORDER Details</h4>
                      <span class="cur-pointer print-btn" (click)="printDetails('print')"><i class="fa fa-print" ></i>PRINT</span>
                      <span class="cur-pointer print-btn ml-3" (click)="printKotDetails('print')"><i class="fa fa-print" ></i>KOT PRINT</span>
                      <span class="cur-pointer closed-btn" (click)="closeDetails()"><i class="fa fa-times"></i></span>
                  </div>
                <div class="status-box">
                    <label>Status</label>
                    <p>Placed</p>
                    <p>Accepted</p>
                    <p>Dispatched</p>
                    <p>Delivered</p>
                </div>
                <div class="text-center">
                    <div>
                        <strong>{{ showDetails?.OutletName | titlecase}}</strong>
                        <div style="float: right;margin-top: -7px;">
                            <div>{{showDetails?.Header1}}: {{showDetails?.Header1Value}}</div>
                            <div>{{showDetails?.Header2}}: {{showDetails?.Header2Value}}</div>
                        </div>
                    </div>
                </div>
                <hr />
                <section>
                    <div class="row">
                        <div class="col-md-6">
                            <div>Name : {{ showDetails?.UserName | titlecase }}</div>
                            <div>Email : {{ showDetails?.UserEmail || '-'}}</div>
                            <div> Mobile : {{ showDetails?.usermobile || '-' }}</div>
                            <div> Address : {{ showDetails?.UserAddress || '-' }}</div>
                        </div>
                        <div class="col-md-6">
                            <div> Address : {{ showDetails?.Address || '-' }}</div> 
                            <div *ngIf="showDetails?.Website">Web : {{ showDetails?.Website  || '-'}}</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-md-6">Order Id : {{ showDetails?.OrderId }}</div>
                        <div class="col-md-6 text-right">Payment Type : {{ showDetails?.PaymentType }}</div>
                        <div class="col-md-6">Pickup Type : {{ showDetails?.PickupType }}</div>
                    </div>
                    <hr/>

                    <div class="row">
                      <div class="col">Bill No : {{ showDetails?.BillNo }}</div>
                      <div class="col text-right">Table No : {{ showDetails?.TableName }}</div>
                    </div>
                    <div class="row">
                      <div class="col">Bill Date : {{ showDetails?.BillDate | date }}</div>
                      <div class="col text-right">Bill Time : {{ showDetails?.BillDate | date:'shortTime'}}</div>
                    </div>
                  </section>
                <div class="item-area">
                    <h4 class="text-center">ITEMS</h4>
                    <table class="item-detail-table">
                        <thead>
                          <tr>
                            <th scope="col">ITEM NAME</th>
                            <th scope="col">QTY</th>
                            <th scope="col">RATE</th>
                            <th scope="col">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of showDetails?.ItemDetailsOrder">
                            <td>{{ item?.ItemName | uppercase }} <span *ngIf="item?.VarationName">({{item?.VarationName}})</span></td>
                            <td>{{ item?.Qty }}</td>
                            <td>{{item?.Rate}}</td>
                            <td>{{item?.TotalAmount}}</td>
                          </tr>
                        <tr class="font-weight-bold">
                            <td class="text-right no-border no-border-right" colspan="3">Sub Total :</td>
                            <td class="no-border no-border-left">{{ showDetails?.TotalAmount }}</td>
                        </tr>
                        <tr class="font-weight-bold" *ngFor="let tax of allTaxDetailsForTable">
                            <td class="text-right no-border no-border-right" colspan="3">{{ tax?.TaxName}}</td>
                            <td class="no-border no-border-left">{{ tax?.TaxAmount }}</td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td class="text-right no-border no-border-right" colspan="3">Round Off :</td>
                            <td class="no-border no-border-left">{{ showDetails?.RoundOff }}</td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td class="text-right no-border no-border-right" colspan="3">Delivery Charge :</td>
                            <td class="no-border no-border-left">{{ showDetails?.Deliverycharge }}</td>
                        </tr>
                        <tr class="font-weight-bold">
                            <td class="text-right no-border no-border-right" colspan="3">Net Amount :</td>
                            <td class="no-border no-border-left">{{ showDetails?.netAmmount?.toFixed(2) }}</td>
                        </tr>
                        <tr><td class="no-border" colspan="4"></td></tr>
                        <tr><td class="no-border text-right font-weight-bold" colspan="4">{{ showDetails?.amounttInWords | uppercase }} <span *ngIf="showDetails?.amounttInWords?.trim()">&nbsp;ONLY</span></td></tr>
                        <tr rowspan="2" ><td class="no-border" colspan="4"></td></tr>
                        </tbody>
                      </table>
                </div>
               </ng-container>
               <ng-template #empty>
                   <h4 class="select-ord"><i class="fa fa-shopping-basket"></i> No Order Selected </h4>
               </ng-template>
                </div>
              </div>

                <ul *ngIf="orderDetails && orderDetails?.length==0" class="order-table-data">
                    <li colspan="3" class="text-center">No Record Found</li>
                </ul>
            </div>

        </div>
    </ng-container>
    <ng-template #second>
        <div class="col-md-12 p-t-10">
            <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input type="text" class="form-control" placeholder="Search">
            </div>
        </div>
        <div class="col-md-12 ">
            <div class="mr-2 d-flex" style="float: right;">
                <div class="d-flex">
                    <div id="circle" class="green-color"></div>
                    <label class="mr-2">Delivery</label>
                </div>
                <div id="circle" class="red-color"></div>
                <label class="mr-2">Limit Exceed</label>
                <div id="circle" class="gold-color"></div>
                <label class="mr-2">Dine In</label>
                <div id="circle" class="blue-color"></div>
                <label class="mr-2">Pickup</label>
            </div>
        </div>
        <div class="col-md-12 row p-t-20  order-content">
            <div class="card mr-2 mt-2 col-md-4 p-0" *ngFor="let order of orderAccepted">
                <div class="card-header d-flex p-0" style="background-color: red; color: white;">
                    <div class="col-lg-4" style="border-right: 1px solid white;">
                        <label>{{ order.BillingType }}</label>
                    </div>
                    <div class="col-lg-4" style="border-right: 1px solid white;">
                        <label>{{ order.OrderId }}</label>
                        <label>Token No.</label>
                    </div>
                    <div class="col-lg-4">
                        <label>{{ order.timeDiff }}</label>
                        <label>MM:SS</label>
                    </div>
                </div>
                <div class="card-body">
                    <table>
                        <tr>
                            <th>Item</th>
                            <th>Qty.</th>
                        </tr>
                        <tr *ngFor="let item of order.ItemDetailsOrder">
                            <td>{{ item.Name }}</td>
                            <td>{{ item.Qty }}</td>
                        </tr>
                    </table>
                </div>
                <div class="card-footer d-flex">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    <button type="button" (click)="deliverOrderFromKitchen(order)" class="cur-pointer">MARK AS DONE</button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [closable]=false></p-confirmDialog>

<p-dialog header="Select Delivery Time" [(visible)]="showTimeSlots" [blockScroll]=true [modal]=true [closable]=false>
    <p-dropdown *ngIf="showTimeSlots" [options]="timeslots" placeholder="Select a Timeslot" [(ngModel)]="selectTime"
        optionLabel="FoodPrepareTime" appendTo="body"></p-dropdown>
    <ng-template pTemplate="footer">
        <button type="button" (click)="cancelSlot()" class="btn btn-danger">CANCEL</button>
        <button type="button" (click)="timeSlotSelected()" class="btn btn-primary">SUBMIT</button>
    </ng-template>
</p-dialog>

<p-dialog header="Select Delivery Person" [(visible)]="showdelpersons" [blockScroll]=true [modal]=true [closable]=false>
    <p-table [value]="deliveryPersonsData">
        <ng-template pTemplate="header">
            <th>Delivery Boy Name</th>
            <th>Contact No.</th>
            <th>Select</th>
        </ng-template>
        <ng-template pTemplate="body" let-del>
            <tr>
                <td>
                    {{ del.empName }}
                </td>
                <td>
                    {{ del.mobileno }}
                </td>
                <td>
                    <p-radioButton name="groupname" (click)="selectedDelPerson=del"></p-radioButton>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <button type="button" (click)="cancelDelivery()" class="btn btn-danger">CANCEL</button>
        <button type="button" (click)="deliveryAssigned()" class="btn btn-primary">SUBMIT</button>
    </ng-template>
</p-dialog>
<p-dialog header="Reject Reasons" [(visible)]="showrejpop" [blockScroll]=true [modal]=true [closable]=false>
    <label>Total Rejection Count: {{ rejectReasons.length }}</label>
    <table>
        <tr>
            <th>Delivery Person Name</th>
            <th>Reason</th>
        </tr>
        <tr *ngFor="let reason of rejectReasons">
        <td>{{ reason.DeliveryBoyName }}</td>
        <td>{{ reason.DeliveryRemark }}</td>
        </tr>
    </table>
    <ng-template pTemplate="footer">
        <button type="button" (click)="showrejpop=false;" class="btn btn-danger">CLOSE</button>
    </ng-template>
</p-dialog>
<button id="print_details" printTitle="ORDER DETAILS" [useExistingCss]="true" printSectionId="print-sec" ngxPrint
    style="display: none;">print</button>
<app-print-details #printDetailRef [showDetails]="showDetails" [taxes]="allTaxDetailsForTable"></app-print-details>
<app-print-kot-receipt #kotPrintDetailRef [showDetails]="showDetails" [taxes]="allTaxDetailsForTable"></app-print-kot-receipt>
