import { APP_BASE_HREF, HashLocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HashPreserveQueryLocationStrategyService extends HashLocationStrategy {
  private initialQueryParam: string;
  constructor(
    _platformLocation: PlatformLocation,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string
   ) {
    super(_platformLocation, _baseHref);
    this.initialQueryParam = window.location.search || '';
  }

  prepareExternalUrl(internal: string): string {
    const hash = super.prepareExternalUrl(internal);
    if (this.initialQueryParam.includes('Token')) {
      const token = this.initialQueryParam.split('=')[1];
      localStorage.setItem('toke', token);
    }
    return  hash;
  }
}
