<!doctype html>
<html lang="en" id="kot-print-bill" style="display: none;">

<body class="auto-height" [ngStyle]="{ 'width':  (showDetails?.kotSetting)?(showDetails?.kotSetting):3 + 'in' }">
	<div [ngStyle]="{ 'width':  (showDetails?.kotSetting)?(showDetails?.kotSetting):3 + 'in' }">
		<div class="heading-part">
			<div>KOT Invoice</div>
			<div>
				<b>{{ showDetails?.OutletName | titlecase }}</b>
			</div>
			<div>
				First KOT
			</div>
			<hr />
		</div>

		<section class="bill-info-container">
			<div class="use-info-print-cls">
				<div class="width-50-percentage">
					<div>{{showDetails?.KOTBy}}</div>
				</div>
				<div class="width-50-percentage text-right">
					<div> Cover : {{(showDetails?.pax)?(showDetails?.pax):1}}</div>
					<!-- <div> Session : 1</div> -->
				</div>
			</div>
			<hr />



			<!-- <div class="use-info-print-cls">
				<div class="width-50-percentage">
					<div>Name : {{ showDetails?.UserName | titlecase }}</div>
					<div>Email : {{ showDetails?.UserEmail || '-'}}</div>
					<div> Mobile : {{ showDetails?.usermobile || '-' }}</div>
					<div> Address : {{ showDetails?.UserAddress || '-' }}</div>
				</div>
				<div class="width-50-percentage text-right">
					<div> Address : {{ showDetails?.Address || '-' }}</div>
					<div *ngIf="showDetails?.Website">Web : {{ showDetails?.Website || '-'}}</div>
				</div>

			</div>
			<hr /> -->
			<div class="bill-info">
				<div>Order Id : {{ showDetails?.OrderId }}</div>
				<div>Payment Type : {{ showDetails?.PaymentType }}</div>
			</div>
			<div class="bill-info">
				<div>Pickup Type : {{ showDetails?.PickupType }}</div>
			</div>
			<hr />

			<div class="bill-info">
				<div>Bill No. : {{ showDetails?.BillNo }}</div>
				<div>Table No. : {{ showDetails?.TableName }}</div>
			</div>
			<div class="bill-info">
				<div>Bill Date : {{ showDetails?.BillDate | date }}</div>
				<div>Bill Time : {{ showDetails?.BillDate | date:'shortTime'}}</div>
			</div>
			<div class="bill-info">
				<div>Time : {{currentDate | date:'mediumTime'}}</div>
				<div> Date : : {{currentDate | date:'dd/MM/yyyy'}}</div>
			</div>

		</section>
		<div class="item-table-container">
			<table class="item-table">
				<thead>
					<tr class="item-table-heading-row">
						<th class="item-table-heading-col text-left" scope="col">ITEM NAME</th>
						<th class="item-table-heading-col text-center" scope="col">QTY</th>
						<!-- <th class="item-table-heading-col text-center" scope="col">RATE</th>
						<th class="item-table-heading-col text-right" scope="col">AMOUNT</th> -->
					</tr>
				</thead>
				<tbody>
					<tr class="item-table-value-row" *ngFor="let item of showDetails?.ItemDetailsOrder">
						<td class="item-table-value-col text-left">{{ item?.ItemName | uppercase }}<span
								*ngIf="item?.VarationName">({{item?.VarationName}})</span></td>
						<td class="item-table-value-col text-center">{{ item?.Qty }}</td>
						<!-- <td class="item-table-value-col text-center">{{item?.Rate}}</td>
						<td class="item-table-value-col text-right">{{item?.TotalAmountWithTax}}</td> -->
					</tr>
					<!-- <tr>
						<td colspan="4"></td>
					</tr>
					<tr>
						<td class="last-row" colspan="4"></td>
					</tr>
					<tr class="text-right font-weight-bold">
						<td colspan="3">Sub Total :</td>
						<td>{{ showDetails?.TotalAmount }}</td>
					</tr>
					<tr class="text-right font-weight-bold" *ngFor="let tax of taxes">
						<td colspan="3">{{ tax?.TaxName}}</td>
						<td>{{ tax?.TaxAmount }}</td>
					</tr>
					<tr class="text-right font-weight-bold">
						<td colspan="3">Round Off :</td>
						<td>{{ showDetails?.RoundOff }}</td>
					</tr>
					<tr class="text-right font-weight-bold">
						<td colspan="3">Delivery Charge :</td>
						<td>{{ showDetails?.Deliverycharge }}</td>
					</tr>
					<tr>
						<td colspan="4"></td>
					</tr>
					<tr>
						<td class="last-row" colspan="4"></td>
					</tr>
					<tr class="text-right font-weight-bold">
						<td colspan="3">Net Amount :</td>
						<td>{{ showDetails?.TotalAmountWithTax?.toFixed(2) }}</td>
					</tr>
					<tr>
						<td colspan="4"></td>
					</tr>
					<tr>
						<td class="text-right font-weight-bold" colspan="4">{{ showDetails?.amounttInWords | uppercase
							}} <span *ngIf="showDetails?.amounttInWords?.trim()">&nbsp;ONLY</span></td>
					</tr>
					<tr>
						<td colspan="4"></td>
					</tr>
					<tr>
						<td colspan="4"></td>
					</tr> -->
				</tbody>
			</table>
		</div>
		<!-- <div class="bill-info font-weight-bold footer-info">
			<div>User Name: </div>
			<div>Guest Signature</div>
		</div> -->
	</div>
</body>

</html>
