import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPrintModule} from 'ngx-print';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule} from '@angular/fire/database';
import { AngularFireAuthModule} from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { KdsViewComponent } from './kds-view/kds-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import {TableModule} from 'primeng/table';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import { KDSService } from './service/kds-service';
import { PrintDetailsComponent } from './print-details/print-details.component';
import { MessagingService } from 'src/app/service/MessagingService';
import { environment } from 'src/environments/environment';
import { LocationStrategy } from '@angular/common';
import { HashPreserveQueryLocationStrategyService } from './service/hash-preserve-query-location-strategy.service';
import { PrintKotReceiptComponent } from './print-kot-receipt/print-kot-receipt.component';
import { OrderByKotPipe } from './orderbykot.pipe';

@NgModule({
  declarations: [
    AppComponent,
    KdsViewComponent,
    PrintDetailsComponent,
    PrintKotReceiptComponent,
    OrderByKotPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TableModule,
    RadioButtonModule,
    FormsModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    DialogModule,
    DropdownModule,
    NgxPrintModule,
    CalendarModule,
    ToastModule,
  //  AngularFireModule.initializeApp(environment.firebase),
    // AngularFireMessagingModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule
  ],
  providers: [
    MessageService,
    KDSService,
    ConfirmationService,
    MessagingService,
    {
      provide: LocationStrategy,
      useClass: HashPreserveQueryLocationStrategyService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
