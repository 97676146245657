import { KDSService } from './../service/kds-service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-details',
  templateUrl: './print-details.component.html',
  styleUrls: ['./print-details.component.css'],
})
export class PrintDetailsComponent implements OnInit {
  @Input() showDetails: any;
  @Input() taxes?: [];

  constructor(private kdsService: KDSService) { }

  ngOnInit(): void { }

  print(req): void {
    this.kdsService.printBill(req).subscribe((res) => {

    });
  }
}
