import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";


const basePath = environment.rootApi;
@Injectable()
export class KDSService {
  authToken: string;
  constructor(private http: HttpClient) { }

  setHeaders() {
    let defaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJEZXBhcnRtZW50TGlzdCI6W10sIlBDb2RlIjoiMTA4NDgiLCJMb2dpbklkIjoiQnVzZXIiLCJIb3RlbE5hbWUiOiJIb3RlbCBTd2FydWNoaSBQYWxhY2UiLCJQZXJzb25OYW1lIjoia3Jpc2huYSIsIldlYnNpdGUiOm51bGwsIkhvdGVsSWQiOjEwMDcxLCJWZW5kb3JJZCI6MTAwNTUsIk1vYmlsZU5vIjoiOTQxNDg4NzgxNSIsIkVtYWlsIjoicmVzZXJ2YXRpb25AaG90ZWxzd2FydWNoaXBhbGFjZS5jb20iLCJNZW51U3RyIjoiMSwyLDU0LDMsNCw4LDc3LDU2LDcsNzYsNzQsNjIsNzIsNzksMTgsMTcsNjgsNTgsNTksNjAsNTcsNiwxNiw2Niw2NywxNCwxNSw2NCw4MSw1LDEzLDIxLDEwOCwyMiwyNCwyMywyNiw5Nyw5NCwzMiw5NSwxNDgsMTQ3LDE0MywxNDIsMzEsMTAxLDEwMiwxMDMsOTgsMzAsMjgsMjksMTQ0LDk5LDMzLDQxLDM3LDM4LDM5LDQyLDQwLDQ0LDM0LDM1LDQ1LDQ3LDQ5LDUxLDE0MSw1Miw1MCw1Myw4Miw4OSw5MSw4Niw4Myw4OCw5Miw4Nyw5MCw4NCw4NSwxMTAsMTE1LDExNiwxMTQsMTExLDExMiwxMTcsMTIwLDEyNCwxMTgsMTIzLDEyMiwxMjEsMTE5LDExMywxMjUsMTI3LDEyNiwxMjgsMTMwLDEzMSwxMjksMTQ1LDI3LDE0NiwxOSwyMCwxNTAsMTY1LDE1MiwxNTksMTY5LDE3MSwxNTMsMTcwLDE2NiwxNTQsMTQ5LDE1NSwxNjgsMTcyLDE3NSwxNzMsMTc2LDE3NywiLCJFeHBpcnkiOiIyMDIyLTEwLTI5VDExOjA1OjAxLjA0NDY2NDYrMDU6MzAiLCJMb2dpblR5cGUiOiJicmFuY2giLCJEZWZhdWx0Q3VycmVuY3lJZCI6MTMsIkRlZmF1bHRDdXJyZW5jeU5hbWUiOiJta24iLCJUaGlyZFBhcnR5SW50ZWdyYXRpb24iOiJSZXZ1Z2FpbiIsIlRoaXJkUGFydHlHVUlEIjoiZTkxYjRlNjItMmFmMi00MTcyLWE4OTAtMmZhNjZkNDZkMjRkIiwiVXNlclR5cGUiOiJCcmFuY2giLCJBZ2VudElkIjoiMCJ9.4jm8LTLtwoV36WsTxV5dOjwkP9qU29hmWMn3rxEI1Ok'
    //let defaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJEZXBhcnRtZW50TGlzdCI6W10sIlBDb2RlIjoiMTA4NzgiLCJMb2dpbklkIjoiYnVzZXIiLCJIb3RlbE5hbWUiOiJraW5nIFJlc3RhdXJhbnQiLCJQZXJzb25OYW1lIjoia2luZyIsIldlYnNpdGUiOm51bGwsIkhvdGVsSWQiOjEwMTAxLCJWZW5kb3JJZCI6MTAwODAsIk1vYmlsZU5vIjoiODY5OTQ1NTczMyIsIkVtYWlsIjoiYmFkYWx0cnVlbG9naWNzQGdtYWlsLmNvbSIsIk1lbnVTdHIiOiIgLDEsMiw0LDYsNyw4LDEzLDE1LDE2LDE3LDE4LDE5LDIwLDIxLDI2LDI3LDI4LDI5LDMwLDMxLDUxLDU0LCw1NSw1Niw1Nyw1OCw1OSw2MCw2Miw2NCw2Niw2Nyw2OCw2OSw3MCw3Miw3NCw3Niw3Nyw3OSw4MSw4Miw4Myw4NCw4NSw4Niw4Nyw4OCw4OSw5MCw5MSw5Miw5Myw5NCw5NSw5Niw5Nyw5OCw5OSwxMDAsMTAxLDEwMiwxMDMsMTA0LDEwNSwxMDksMTEwLDExMSwxMTIsMTEzLDExNCwxMTUsMTE2LDExNywxMTgsMTE5LDEyMCwxMjEsMTIyLDEyMywxMjQsMTI4LDEyOSwxMzAsMTMxLDE0MiwxNDMsMTQ0LDE0NiwiLCJFeHBpcnkiOiIyMDIzLTAzLTI4VDEyOjU4OjMwLjM0MjU5NjQrMDU6MzAiLCJMb2dpblR5cGUiOiJicmFuY2giLCJEZWZhdWx0Q3VycmVuY3lJZCI6MTMsImFkbWluSWQiOjAsIkRlZmF1bHRDdXJyZW5jeU5hbWUiOiJta24iLCJUaGlyZFBhcnR5SW50ZWdyYXRpb24iOiIiLCJUaGlyZFBhcnR5R1VJRCI6IiIsIlVzZXJUeXBlIjoiQnJhbmNoIiwiQWdlbnRJZCI6IjAiLCJUaW1laW4iOiIwMDowMDowMCIsIlRpbWVvdXQiOiIwMDowMDowMCIsImRldmljZUlkIjpudWxsfQ.okUJUi11z4dINa_XesotOLZht4jSSdRPMdjvZN-U488'

    //let defaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJEZXBhcnRtZW50TGlzdCI6W10sIlBDb2RlIjoiMTA4NzgiLCJMb2dpbklkIjoiYnVzZXIiLCJIb3RlbE5hbWUiOiJraW5nIFJlc3RhdXJhbnQiLCJQZXJzb25OYW1lIjoia2luZyIsIldlYnNpdGUiOm51bGwsIkhvdGVsSWQiOjEwMTAxLCJWZW5kb3JJZCI6MTAwODAsIk1vYmlsZU5vIjoiODY5OTQ1NTczMyIsIkVtYWlsIjoiYmFkYWx0cnVlbG9naWNzQGdtYWlsLmNvbSIsIk1lbnVTdHIiOiIgLDEsMiw0LDYsNyw4LDEzLDE1LDE2LDE3LDE4LDE5LDIwLDIxLDI2LDI3LDI4LDI5LDMwLDMxLDUxLDU0LCw1NSw1Niw1Nyw1OCw1OSw2MCw2Miw2NCw2Niw2Nyw2OCw2OSw3MCw3Miw3NCw3Niw3Nyw3OSw4MSw4Miw4Myw4NCw4NSw4Niw4Nyw4OCw4OSw5MCw5MSw5Miw5Myw5NCw5NSw5Niw5Nyw5OCw5OSwxMDAsMTAxLDEwMiwxMDMsMTA0LDEwNSwxMDksMTEwLDExMSwxMTIsMTEzLDExNCwxMTUsMTE2LDExNywxMTgsMTE5LDEyMCwxMjEsMTIyLDEyMywxMjQsMTI4LDEyOSwxMzAsMTMxLDE0MiwxNDMsMTQ0LDE0NiwiLCJFeHBpcnkiOiIyMDIzLTAzLTAyVDE5OjQ5OjI4LjkwMDU3OCswNTozMCIsIkxvZ2luVHlwZSI6ImJyYW5jaCIsIkRlZmF1bHRDdXJyZW5jeUlkIjoxMywiYWRtaW5JZCI6MCwiRGVmYXVsdEN1cnJlbmN5TmFtZSI6Im1rbiIsIlRoaXJkUGFydHlJbnRlZ3JhdGlvbiI6IiIsIlRoaXJkUGFydHlHVUlEIjoiIiwiVXNlclR5cGUiOiJCcmFuY2giLCJBZ2VudElkIjoiMCIsIlRpbWVpbiI6IjAwOjAwOjAwIiwiVGltZW91dCI6IjAwOjAwOjAwIiwiZGV2aWNlSWQiOm51bGx9.uVYcb-Jwl4WUrodPDIYMeyaDtFSkcGSWtntDp_CGQ98'
    // let defaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJEZXBhcnRtZW50TGlzdCI6W10sIlBDb2RlIjoiMTA4NzgiLCJMb2dpbklkIjoiQnVzZXIiLCJIb3RlbE5hbWUiOiJraW5nIFJlc3RhdXJhbnQiLCJQZXJzb25OYW1lIjoia2luZyIsIldlYnNpdGUiOm51bGwsIkhvdGVsSWQiOjEwMTAxLCJWZW5kb3JJZCI6MTAwODAsIk1vYmlsZU5vIjoiODY5OTQ1NTczMyIsIkVtYWlsIjoiYmFkYWx0cnVlbG9naWNzQGdtYWlsLmNvbSIsIk1lbnVTdHIiOiIgLDEsMiw0LDYsNyw4LDEzLDE1LDE2LDE3LDE4LDE5LDIwLDIxLDI2LDI3LDI4LDI5LDMwLDMxLDUxLDU0LCw1NSw1Niw1Nyw1OCw1OSw2MCw2Miw2NCw2Niw2Nyw2OCw2OSw3MCw3Miw3NCw3Niw3Nyw3OSw4MSw4Miw4Myw4NCw4NSw4Niw4Nyw4OCw4OSw5MCw5MSw5Miw5Myw5NCw5NSw5Niw5Nyw5OCw5OSwxMDAsMTAxLDEwMiwxMDMsMTA0LDEwNSwxMDksMTEwLDExMSwxMTIsMTEzLDExNCwxMTUsMTE2LDExNywxMTgsMTE5LDEyMCwxMjEsMTIyLDEyMywxMjQsMTI4LDEyOSwxMzAsMTMxLDE0MiwxNDMsMTQ0LDE0NiwiLCJFeHBpcnkiOiIyMDIyLTEwLTI5VDExOjEwOjI1LjA0NTUyNDErMDU6MzAiLCJMb2dpblR5cGUiOiJicmFuY2giLCJEZWZhdWx0Q3VycmVuY3lJZCI6MTMsIkRlZmF1bHRDdXJyZW5jeU5hbWUiOiJta24iLCJUaGlyZFBhcnR5SW50ZWdyYXRpb24iOiIiLCJUaGlyZFBhcnR5R1VJRCI6IiIsIlVzZXJUeXBlIjoiQnJhbmNoIiwiQWdlbnRJZCI6IjAifQ.-2IXl0UVu3uNvjha6j8ecQTPDhs9YPtI8aNoCgV5dts'
    this.authToken = localStorage.getItem('toke') ? localStorage.getItem('toke') : defaultToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authToken.replace('Bearer%20','Bearer ')
    })
    return headers;
  }

  /****************NEW ORDERS DATA ***************************** */
  getNewOrders(obj): Observable<any> {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.get(basePath + `api/DeliveryBoy/OrderGet?Id=${obj.Id}&FromDate=${obj.FromDate}&ToDate=${obj.ToDate}&DeliveryStatus=${obj.DeliveryStatus}`, { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  getOrdersById(id): Observable<any> {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.get(basePath + `api/DeliveryBoy/OrderGet?Id=${id}`, { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Get Delivery Boy and time slot Details***************************** */
  getDeliveryDetails(): Observable<any> {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.get(basePath + 'api/DeliveryBoy/DeliveryBoy', { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Get Admin Status***************************** */
  getAdminStatus(reqParam): Observable<any> {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.post(basePath + 'api/DeliveryBoy/AdminStatus', reqParam, { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Set Delivery Person***************************** */
  setDeliveryPerson(body) {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.post(basePath + 'api/DeliveryBoy/SetDeliveryBoy', body, { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Change Order Status from kitchen***************************** */
  setKitchenStatus(body) {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.post(basePath + 'api/DeliveryBoy/KitchenStatus', body, { headers })
      .pipe(
        map((body: any) => {
          return body;
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Extend Time***************************** */
  extendTime(body) {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.post(basePath + 'api/DeliveryBoy/IncressTimeSloat', body, { headers })
      .pipe(
        map((body: any) => {
          return body
        },
          (error) => {
            return error;
          })
      )
  }

  /****************Cancel Order If not Accepted***************************** */
  getDeliveryBoyCancelledOrder() {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.post(basePath + 'api/DeliveryBoy/DeliveryboyCancelorder', {}, {headers})
    .pipe(
      map((body: any) => {
        return body;
      },
      (error) => {
        return error;
      })
    )
  }

  /****************Rejection Reason List***************************** */
  getRejectedReasons(orderId) {
    const headers: HttpHeaders = this.setHeaders();
    return this.http.get(basePath + `api/DeliveryBoy/DeliveryboyCancelorder?OrderId=${orderId}`, {headers})
    .pipe(
      map((body: any) => {
        return body;
      },
      (error) => {
        return error;
      })
    )
  }
  printBill(req: any) {
    const header = new HttpHeaders();
    header.set('Content-Type', 'text/plain');
    header.set('Accept', '*');
    if(req.PrintFormat=='A3')
    { return this.http.get('http://localhost:8743/testservice/PrintBillA3', { headers: header, params: req });}
   else
   {
    return this.http.get('http://localhost:8743/testservice/PrintBillA3_70mm', { headers: header, params: req });
   }
  }
  PrintKOT(req: any) {

    const header = new HttpHeaders();
    header.set('Content-Type', 'text/plain');
    header.set('Accept', '*');
    if(req.PrintFormat=='A3')
    {
    return this.http.get('http://localhost:8743/testservice/PrintKotA3', { headers: header, params: req });
  }
  else
   {
    return this.http.get('http://localhost:8743/testservice/PrintBillA3_70mm', { headers: header, params: req });
   }
  }
  getSavedPrinterSettings(Id: any) {

    const headers: HttpHeaders = this.setHeaders();
    return this.http.get(basePath + `api/PosPrintterSetting/PosPrintterSetting?Id=${Id}`, {headers})
    .pipe(
      map((body: any) => {
        return body;
      },
      (error) => {
        return error;
      })
    ) 
   }
  }